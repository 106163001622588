@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary-main: 2, 199, 110;
    --color-primary-light: 136, 224, 185;
    --color-primary-dark: 1, 148, 82;

    --color-complementary-blue-main: 39, 183, 255;
    --color-complementary-blue-light: 115, 208, 255;
    --color-complementary-blue-dark: 31, 146, 204;

    --color-complementary-yellow-main: 238, 185, 67;
    --color-complementary-yellow-light: 240, 208, 139;
    --color-complementary-yellow-dark: 186, 144, 52;

    --color-accent-beige-main: 248, 242, 225;
    --color-accent-beige-light: 247, 247, 237;
    --color-accent-beige-dark: 184, 179, 167;

    --color-accent-lilac-main: 179, 132, 250;
    --color-accent-lilac-light: 225, 208, 251;
    --color-accent-lilac-dark: 143, 105, 199;

    --color-accent-red-main: 244, 101, 50;
    --color-accent-red-light: 247, 156, 123;
    --color-accent-red-dark: 194, 80, 39;

    --color-accent-purple-main: 80, 61, 249;
    --color-accent-purple-light: 147, 135, 251;
    --color-accent-purple-dark: 63, 48, 199;

    --color-error-main: 255, 59, 59;
    --color-error-light: 255, 135, 135;
    --color-error-dark: 128, 29, 29;

    --color-success-main: 87, 235, 161;
    --color-success-light: 159, 238, 199;
    --color-success-dark: 40, 107, 73;

    --color-info-main: 0, 99, 247;
    --color-info-light: 75, 144, 249;
    --color-info-dark: 0, 48, 120;

    --color-warning-main: 255, 204, 0;
    --color-warning-light: 255, 219, 77;
    --color-warning-dark: 128, 102, 0;

    --color-white-main: 255, 255, 255;
    --color-white-light: 248, 248, 248;
    --color-white-dark: 235, 235, 240;

    --color-black-main: 0, 0, 0;
    --color-black-light: 153, 153, 153;
    --color-black-dark: 77, 77, 77;

    --text-color-base: var(--color-black-main);
    --text-color-inverted: var(--color-black-main);
    --text-color-list-item: var(--color-black-dark);

    --color-alert-error: 255, 231, 231;
    --color-alert-success: 236, 252, 244;
    --color-alert-info: 219, 233, 254;
    --color-alert-warning: 255, 248, 219;
  }

  .theme-arus {
    --color-primary-main: 139, 24, 31;
    --color-primary-light: 220, 112, 130;
    --color-primary-dark: 89, 15, 32;

    --text-color-base: var(--color-white-main);
    --text-color-inverted: var(--color-primary-main);
    --text-color-list-item: var(--color-white-main);
  }
}

@layer components {
  .montserrat-bold {
    @apply font-montserrat font-bold not-italic leading-[1.35];
  }

  .montserrat-semibold {
    @apply font-montserrat font-semibold not-italic leading-[1.35];
  }

  .sora-normal {
    @apply font-sora font-normal not-italic leading-[1.35];
  }

  .sora-semibold {
    @apply font-sora font-semibold not-italic leading-[1.35];
  }

  .h1 {
    @apply montserrat-bold;
    font-size: 1.75rem;
    @media (min-width: 600px) {
      font-size: 2.037rem;
    }
    @media (min-width: 900px) {
      font-size: 2.2222rem;
    }
    @media (min-width: 1200px) {
      font-size: 2.5926rem;
    }
  }

  .h2 {
    @apply montserrat-bold;
    font-size: 1.5rem;
    @media (min-width: 600px) {
      font-size: 1.6667rem;
    }
    @media (min-width: 900px) {
      font-size: 1.8519rem;
    }
    @media (min-width: 1200px) {
      font-size: 2.037rem;
    }
  }

  .h3 {
    @apply montserrat-bold;
    font-size: 1.375rem;
    @media (min-width: 600px) {
      font-size: 1.4815rem;
    }
    @media (min-width: 900px) {
      font-size: 1.6667rem;
    }
    @media (min-width: 1200px) {
      font-size: 1.6667rem;
    }
  }

  .h4 {
    @apply montserrat-bold;
    font-size: 1.25rem;
    @media (min-width: 600px) {
      font-size: 1.2963rem;
    }
    @media (min-width: 900px) {
      font-size: 1.4815rem;
    }
    @media (min-width: 1200px) {
      font-size: 1.4815rem;
    }
  }

  .h5 {
    @apply montserrat-semibold;
    font-size: 1.125rem;
    @media (min-width: 600px) {
      font-size: 1.1111rem;
    }
    @media (min-width: 900px) {
      font-size: 1.2963rem;
    }
    @media (min-width: 1200px) {
      font-size: 1.2963rem;
    }
  }

  .h6 {
    @apply montserrat-semibold;
    font-size: 1rem;
  }

  .lead1 {
    @apply montserrat-bold;
    font-size: 1rem;
  }

  .lead2 {
    @apply montserrat-bold;
    font-size: 0.875rem;
  }

  .body1 {
    @apply sora-normal;
    font-size: 1rem;
  }

  .body2 {
    @apply sora-normal;
    font-size: 0.875rem;
  }

  .blockquote {
    @apply montserrat-semibold;
    font-size: 1.25rem;
  }

  .capitalized {
    @apply sora-normal;
    font-size: 0.875rem;
    letter-spacing: 0.143em;
    text-transform: uppercase;
  }

  .small {
    @apply sora-normal;
    font-size: 0.75rem;
  }

  .tiny {
    @apply sora-semibold;
    font-size: 0.625rem;
  }
}
