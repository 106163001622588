.rdg {
  background-color: #ffffff;
  border-color: #ddd;
  box-shadow: 0px 8px 16px 0px rgba(96, 97, 112, 0.16);
  border-radius: 0.2rem;
  block-size: auto;
}

.rdg-cell {
  background-color: #ffffff;
  color: black;
  border-color: #ddd;
}

.rdg-top-summary-row > div {
  border-block-end: 1px solid #ddd !important;
}

.error-cell {
  background: rgba(255, 59, 59, 0.2);
}

.rdg-cell[aria-selected='true'] {
  outline: 2px solid rgb(2, 199, 110) !important;
}
